import React from "react";
import Api from "../api/api";
import Cookies from "js-cookie";
import { ApiError, shapeApiError } from "../general/errors";
import { Nullable } from "../types";
import { useLocation, useNavigate } from "react-router";

interface AuthContextInterface {
  authed: boolean;
  login: ({
    email,
    password,
    path,
  }: {
    email: string;
    password: string;
    path?: string;
  }) => Promise<any>;
  logout: () => Promise<any>;
  authErrors: Nullable<ApiError>;
  setAuthedGlobal: (authed: boolean) => void;
}

const AuthContext = React.createContext<AuthContextInterface | null>(null);

export const useAuth = () => {
  const [authed, setAuthed] = React.useState(false);
  const [authErrors, setAuthErrors] = React.useState<Nullable<ApiError>>(null);
  const navigate = useNavigate();
  const { state } = useLocation();

  const setAuthedGlobal = (authed: boolean) => {
    setAuthed(authed);
  };

  const login = async ({
    email,
    password,
    path,
  }: {
    email: string;
    password: string;
    path?: string;
  }) => {
    try {
      const { accessToken } = await Api.login({
        email,
        password,
      });
      Cookies.set("token", accessToken);
      setAuthed(true);
      navigate(state?.path || "/");
    } catch (e: any) {
      setAuthErrors(shapeApiError(e));
    }
  };

  const logout = async () => {
    Cookies.remove("token");
    setAuthed(false);
  };

  return {
    authed,
    login,
    authErrors,
    logout,
    setAuthedGlobal,
  };
};

export function AuthProvider({ children }: any) {
  const { authed, login, logout, setAuthedGlobal, authErrors } = useAuth();

  return (
    <AuthContext.Provider
      value={{ authed, login, logout, setAuthedGlobal, authErrors }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default function AuthConsumer() {
  return React.useContext(AuthContext);
}
