import { createAction } from "@reduxjs/toolkit";
import { GrantState } from "../../../types";
import { GrantActionTypes } from "../types";

export type updateNotes = {
  type: GrantActionTypes.updateNotes;
};

const action = createAction<
  {
    grantUuid: string;
    note: string;
  },
  GrantActionTypes.updateNotes
>(GrantActionTypes.updateNotes);

export const reducer = (
  state: GrantState,
  {
    payload,
  }: {
    payload: {
      grantUuid: string;
      note: string;
    };
  }
) => {
  const { grantUuid, note } = payload;
  state.grants[grantUuid].note = note;
};

export default action;
