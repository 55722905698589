import classNames from "classnames";
import React from "react";

interface Props {
  onClick: () => void;
  text: string;
  disabled?: boolean;
}

const Button: React.FC<Props> = ({ onClick, text, disabled = false }) => (
  <button
    disabled={disabled}
    className={classNames(
      "bg-maroon hover:bg-maroonDark text-white rounded  text-sm uppercase py-2 px-4",
      {
        "opacity-30": disabled,
      }
    )}
    onClick={onClick}
  >
    {text}
  </button>
);

export default Button;
