import GrantConsumer from "../GrantsContext";
import { getSelectedGrants, getSelectedGrantUuids } from "../grantsSelectors";
import { useNavigate } from "react-router";
import Button from "../../general/Button";
import React from "react";
import GrantTable from "../GrantsTable";

const GrantsSummary = () => {
  const state = GrantConsumer();

  const navigate = useNavigate();

  const selectedGrants = getSelectedGrants(state);

  const selectedGrantUuids = getSelectedGrantUuids(state);

  const noActionedGrants = !selectedGrants.filter(
    (grant) => grant.action !== null
  ).length;

  React.useEffect(() => {
    if (!selectedGrantUuids.length) {
      navigate("/");
    }
  }, [selectedGrantUuids, navigate]);

  return (
    <div className="p-10">
      <h1 className="text-xl font-bold font-playfairDisplay">
        Approval Summary
      </h1>
      <div className="text-cloud text-xs mt-3">Mihi</div>
      <div className="mt-10 text-sm">
        <div className="my-5">Summary</div>
        {noActionedGrants ? (
          <div>No changes made.</div>
        ) : (
          <GrantTable
            grants={selectedGrants}
            disabled
            grantErrors={{}}
            allowSelectAll={false}
          />
        )}
      </div>
      <div className="mt-40 w-full flex justify-end">
        <Button text="Back" onClick={() => navigate("/")} />
      </div>
    </div>
  );
};

export default GrantsSummary;
