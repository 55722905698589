import { orderBy } from "lodash";
import { GrantState } from "./types";
import { createSelector } from "reselect";

export const getAllSelected = (state: GrantState) => {
  const { grants } = state;

  const numberOfGrants = Object.keys(grants).length;

  const selectedCount = Object.keys(grants).filter(
    (grantUuid) => grants[grantUuid].selected
  ).length;

  return numberOfGrants - selectedCount === 0;
};

export const getSelectedGrantUuids = (state: GrantState) => {
  const sortedGrants = getSortedGrants(state);
  return sortedGrants
    .filter((grant) => grant.selected)
    .map((grant) => grant.uuid);
};

export const getSelectedGrants = (state: GrantState) => {
  const sortedGrants = getSortedGrants(state);
  return sortedGrants.filter((grant) => grant.selected);
};

export const getGrantsObject = (state: GrantState) => {
  const { grants } = state;

  return grants;
};

export const getSortedGrants = createSelector(getGrantsObject, (grants) =>
  orderBy(
    Object.keys(grants).map((grantUuid) => grants[grantUuid]),
    ["name", "memberName"],
    "asc"
  )
);

export const getNewResoltuion = (state: GrantState) => {
  const { authorisationNotes } = state;

  return authorisationNotes && authorisationNotes["new-note"];
};

export const getAuthorizationNote = (state: GrantState, uuid: string) => {
  const { authorisationNotes } = state;
  return authorisationNotes && authorisationNotes[uuid];
};

export const getGrantsTotal = (state: GrantState) => {
  const { grants } = state;

  const selectedGrantUuids = getSelectedGrantUuids(state);

  const total = selectedGrantUuids.reduce((accum, grantUuid) => {
    const approvedAmount = grants[grantUuid].approvedAmount
      ? grants[grantUuid].approvedAmount / 100
      : 0;

    return accum + approvedAmount;
  }, 0);

  return total;
};
