import { useNavigate } from "react-router";
import AuthConsumer from "../auth/authHooks";
import Logo from "../assets/Logo";
import AuthButton from "../general/AuthButton";
import TeamDropDown from "../teams/TeamDropDown/index";
import { Nullable } from "../types";
import { Team } from "../teams/types";

interface Props {
  teams: Nullable<Team[]>;
  setCurrentTeam: (teamUuuid: string) => void;
  currentTeam?: Team;
  loading: boolean;
}

const Header = ({ teams, setCurrentTeam, currentTeam, loading }: Props) => {
  const { authed, logout } = AuthConsumer() || {};
  const navigate = useNavigate();

  const handleLogout = () => {
    if (logout) {
      logout();
      navigate("/login");
    }
  };

  return (
    <div className="w-full">
      <div className="h-28 flex justify-between px-10 items-center">
        <button onClick={() => navigate("/")}>
          <Logo />
        </button>
        <div className="flex justify-center items-center">
          <div className="mr-12">
            <TeamDropDown
              loading={loading}
              teams={teams}
              setCurrentTeam={setCurrentTeam}
              currentTeam={currentTeam}
            />
          </div>
          {authed && <AuthButton onClick={handleLogout} text="Logout" />}
        </div>
      </div>
      <div className="bg-oat h-20 pl-10 pt-4">
        <div className="text-dijon font-bold">Grant Approval</div>
        <div className="uppercase text-bronze text-xs">Tahua Aawhina</div>
      </div>
    </div>
  );
};

export default Header;
