import { createAction } from "@reduxjs/toolkit";
import { GrantState, Resolution } from "../../../types";
import { GrantActionTypes } from "../types";

export type updateAuthorizationNote = {
  type: GrantActionTypes.updateAuthorizationNote;
};

const action = createAction<
  {
    authorisationNote: Resolution;
  },
  GrantActionTypes.updateAuthorizationNote
>(GrantActionTypes.updateAuthorizationNote);

export const reducer = (
  state: GrantState,
  {
    payload,
  }: {
    payload: {
      authorisationNote: Resolution;
    };
  }
) => {
  const { authorisationNote } = payload;
  state.authorisationNotes = {
    ...state.authorisationNotes,
    [authorisationNote.uuid]: authorisationNote,
  };
};

export default action;
