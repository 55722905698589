const Logo = () => {
  return (
    <svg width="205" height="48" viewBox="0 0 205 48" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.6358 47.6219H95.6649V13.8094H79.6358V47.6219Z"
        className="text-bronze fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.0217 0.252502L55.7473 6.95578L53.8784 0.252502H50.3845L48.5271 6.9416L47.2624 0.252502H43.7411L46.4069 12.2432H50.0393L52.1336 4.69328L54.2395 12.2432H57.879L60.5262 0.252502H57.0217Z"
        className="text-bronze fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.5516 0.24054L68.0248 12.2587H71.8222L72.4247 10.2746H76.6374L77.2391 12.2587H81.1306L76.6232 0.24054H72.5516ZM74.5377 3.35414L75.8476 7.67523H73.2199L74.5377 3.35414Z"
        className="text-bronze fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.9359 12.2553H95.6649V0.242371H91.9359V12.2553Z"
        className="text-bronze fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.426 0.242126L111.532 4.7822V0.242126H107.819V12.255H111.532V9.33114L113.46 7.32308L115.997 12.255H120.57L115.978 4.75738L120.367 0.242126H115.426Z"
        className="text-bronze fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.162 0.24054L128.636 12.2587H132.433L133.036 10.2746H137.248L137.849 12.2587H141.741L137.234 0.24054H133.162ZM135.148 3.35414L136.459 7.67523H133.831L135.148 3.35414Z"
        className="text-bronze fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.344 0.242126V3.21388H154.138V12.255H157.852V3.21388H161.648V0.242126H150.344Z"
        className="text-bronze fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M175.868 4.2757C175.974 3.98845 176.108 3.7411 176.282 3.54694C176.335 3.48134 176.396 3.42017 176.456 3.35988C176.516 3.30669 176.583 3.25438 176.643 3.19941C176.777 3.10721 176.91 3.02653 177.058 2.95295C177.058 2.95295 177.065 2.96004 177.065 2.95295C177.359 2.82528 177.693 2.75879 178.061 2.75879C178.836 2.75879 179.445 3.01944 179.899 3.53365C179.954 3.60014 180.007 3.66752 180.06 3.7411C180.4 4.25619 180.575 5.00356 180.575 5.97169C180.575 6.06921 180.571 6.15964 180.569 6.25273C180.566 6.39103 180.561 6.52667 180.551 6.65611C180.543 6.782 180.532 6.90435 180.519 7.02049C180.492 7.24656 180.462 7.46554 180.414 7.65615V7.66236C180.36 7.88932 180.294 8.08968 180.213 8.26965C180.127 8.45051 180.033 8.60388 179.926 8.73066C179.873 8.79095 179.82 8.85123 179.759 8.91152C179.345 9.29895 178.783 9.5002 178.094 9.5002C177.9 9.5002 177.72 9.48601 177.546 9.45232C177.379 9.41863 177.218 9.36544 177.065 9.30515C176.837 9.20586 176.63 9.07199 176.448 8.89822C176.39 8.83794 176.329 8.77765 176.275 8.71116C176.221 8.65176 176.169 8.57817 176.115 8.50459C176.074 8.431 176.028 8.34324 175.981 8.25635C175.734 7.74215 175.607 7.03379 175.607 6.14013C175.607 5.3839 175.694 4.7633 175.868 4.2757ZM174.892 11.604C175.774 12.0304 176.878 12.245 178.215 12.245C179.532 12.245 180.635 11.9976 181.518 11.5029C182.4 11.0091 183.069 10.3203 183.537 9.43902C184.006 8.55069 184.239 7.41501 184.239 6.03197C184.239 4.12144 183.705 2.64531 182.634 1.58321C181.564 0.52732 180.047 -0.000183105 178.074 -0.000183105C176.142 -0.000183105 174.644 0.534413 173.56 1.60981C172.484 2.6923 171.943 4.19502 171.943 6.12595C171.943 7.51519 172.217 8.66417 172.765 9.58619C173.306 10.5073 174.016 11.182 174.892 11.604Z"
        className="text-bronze fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.8936 34.7296L53.8083 22.5722L58.7034 34.7296H48.8936ZM46.4132 13.8076L29.5286 47.6219H43.6781L45.9313 42.0508H61.6453L63.8905 47.6219H78.402L61.5849 13.8076H46.4132Z"
        className="text-bronze fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.369 32.4768L111.194 13.8076H97.9788V47.6237H111.287V29.0565L128.369 47.6237H141.741V13.8076H128.369V32.4768Z"
        className="text-bronze fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M172.578 34.0898C172.578 35.9303 171.915 37.355 170.592 38.3594C169.267 39.363 167.44 39.8666 165.11 39.8666C162.76 39.8666 160.922 39.3559 159.598 38.3373C158.273 37.3168 157.611 35.9019 157.611 34.0898V13.8079H144.054V33.6172C144.054 35.2494 144.474 37.1227 145.313 39.238C145.832 40.5519 146.796 41.8294 148.202 43.068C149.611 44.3065 151.163 45.2631 152.86 45.9342C154.557 46.6062 156.669 47.0566 159.194 47.2835C161.721 47.5105 164.052 47.624 166.187 47.624C169.882 47.624 173.046 47.2534 175.681 46.5122C177.579 45.9839 179.39 45.0654 181.118 43.7586C182.844 42.4527 184.112 40.926 184.92 39.1804C185.729 37.4348 186.134 35.581 186.134 33.6172V13.8079H172.578V34.0898Z"
        className="text-bronze fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.448 0.273254V37.234C188.841 37.2109 190.69 36.921 194.564 33.7843C198.974 30.2115 199.45 28.3657 199.45 28.3657L203.741 27.5908C203.741 27.5908 202.074 33.2471 199.45 37.2357C196.828 41.2252 191.823 44.4993 191.823 44.4993L193.194 39.1418C193.194 39.1418 193.93 40.2217 195.042 39.9743C195.577 39.8555 196.293 39.5585 196.351 37.8306C196.417 35.9254 194.682 35.5699 194.266 35.6293C190.343 36.1896 188.484 47.7149 188.484 47.7149H204.477V0.273254H188.448Z"
        className="text-bronze fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.4064 0.460205H0.000274658V7.32839H11.9551V47.7156H27.9273V47.564C27.6957 46.2236 25.832 36.1522 22.1713 35.6283C21.7533 35.5707 20.0193 35.9244 20.0858 37.8314C20.1453 39.5593 20.8606 39.8563 21.3966 39.9751C22.5086 40.2215 23.2443 39.1408 23.2443 39.1408L24.6154 44.4992C24.6154 44.4992 19.6084 41.2251 16.986 37.2356C14.3636 33.247 12.6943 27.5925 12.6943 27.5925L16.986 28.3656C16.986 28.3656 17.4625 30.2123 21.8732 33.7833C25.5827 36.7888 27.4321 37.1789 27.9273 37.2285V7.32839H39.4064V0.460205Z"
        className="text-bronze fill-current"
      />
    </svg>
  );
};

export default Logo;
