import Button from "../general/Button";
import { useSaveSelectedGrants } from "./GrantHooks";
import GrantConsumer from "./GrantsContext";
import {
  getAllSelected,
  getSelectedGrants,
  getSelectedGrantUuids,
} from "./grantsSelectors";
import { GrantErrors } from "./types";

interface Props {
  setErrors: (grantErrors: GrantErrors) => void;
  customText?: string;
  authorisationUuid?: string;
}

const GrantsSubmitButton: React.FC<Props> = ({
  setErrors,
  customText,
  authorisationUuid,
}) => {
  const grantState = GrantConsumer();

  const selectedGrants = getSelectedGrants(grantState);

  const saveSelectedGrants = useSaveSelectedGrants(authorisationUuid);

  const handleClick = () => {
    let errors: GrantErrors = {};
    selectedGrants.forEach((grant) => {
      if (
        (!grant.note || grant.note === "") &&
        (grant.action !== "APPROVE" ||
          grant.approvedAmount !== grant.requestedAmount)
      ) {
        errors = {
          ...errors,
          [grant.uuid]: { note: "Please add a note." },
        };
      }
    });
    setErrors(errors);
    if (!Object.keys(errors).length) {
      saveSelectedGrants();
    }
  };

  const selectedGrantUuids = getSelectedGrantUuids(grantState);

  const allGrantsSelected = getAllSelected(grantState);

  const submitText = () => {
    if (customText) {
      return customText;
    }
    return allGrantsSelected ? "Submit All" : "Submit Selected";
  };

  return (
    <Button
      disabled={!selectedGrantUuids.length}
      onClick={handleClick}
      text={submitText()}
    />
  );
};

export default GrantsSubmitButton;
