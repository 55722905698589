import React from "react";
import CheckIcon from "./CheckIcon";
import ReactTooltip from "react-tooltip";

interface Props {
  handleClick: () => void;
  selected: boolean;
  toolTipText?: string;
  toolTipId?: string;
}

const Checkbox: React.FC<Props> = ({
  handleClick,
  selected,
  toolTipText,
  toolTipId,
}) => {
  return (
    <>
      {" "}
      <button
        data-tip
        data-for={toolTipId}
        onClick={handleClick}
        className="ml-2 border border-ghost border-1 rounded w-5 h-5 flex items-center justify-center"
      >
        <div>{selected && <CheckIcon />}</div>
      </button>
      {toolTipId && (
        <ReactTooltip id={toolTipId} place="top" effect="solid">
          {toolTipText}
        </ReactTooltip>
      )}
    </>
  );
};

export default Checkbox;
