import classNames from "classnames";
import GrantDropDown from "./GrantDropDown";

import GrantCheckbox from "./GrantCheckbox";
import AmountInput from "./AmountInput";
import { currencyFormatter } from "../general/utils";
import TotalApproved from "./TotalApproved";
import NotesInput from "./NotesInput";
import BulkChange from "./BulkChange";
import { Grant, GrantErrors } from "./types";
import Checkbox from "./Checkbox";
import { useAllSelected, useToggleAllSelected } from "./GrantHooks";

interface Props {
  grants: Grant[];
  handleSelect?: (grantUuid: string) => (action: string) => void;
  disabled?: boolean;
  grantErrors: GrantErrors;
  allowSelectAll?: boolean;
}

const GrantTable: React.FC<Props> = ({
  grants,
  handleSelect,
  disabled = false,
  grantErrors,
  allowSelectAll = true,
}) => {
  const toggleAllSelected = useToggleAllSelected();
  const allSelected = useAllSelected();
  return (
    <>
      <table className="w-full text-xs">
        <tbody>
          <tr className="text-left">
            <th className="w-14">
              {allowSelectAll && (
                <Checkbox
                  handleClick={toggleAllSelected}
                  selected={allSelected}
                  toolTipText={allSelected ? "Deselect all" : "Select all"}
                  toolTipId="selectAll"
                />
              )}
            </th>
            <th className="w-44">Grant</th>
            <th>Member</th>
            <th>Marae</th>
            <th className="w-40 pr-4">Requested Amount</th>
            <th className="w-40 pr-4">Approved Amount</th>
            <th className="w-52 pr-4">Action</th>
            <th className="w-80">Note/Reason</th>
          </tr>
          {grants.map((grant) => {
            const {
              uuid: grantUuid,
              name,
              memberName,
              marae,
              requestedAmount,
              selected,
              action,
            } = grant;

            if (disabled && !action) {
              return null;
            }
            return (
              <tr
                className={classNames("h-16 hover:text-coal", {
                  "bg-mist": selected,
                })}
                key={grantUuid}
              >
                <td>{!disabled && <GrantCheckbox grantUuid={grantUuid} />}</td>
                <td className="pr-4">{name}</td>
                <td className="pr-4">{memberName}</td>
                <td className="pr-4">{marae}</td>
                <td>{currencyFormatter.format(requestedAmount / 100)}</td>
                <td>
                  {<AmountInput grantUuid={grantUuid} disabled={disabled} />}
                </td>
                <td>
                  {
                    <GrantDropDown
                      disabled={disabled}
                      currentAction={grant.action}
                      handleSelect={handleSelect && handleSelect(grantUuid)}
                    />
                  }
                </td>
                <td className="relative">
                  {<NotesInput grantUuid={grantUuid} disabled={disabled} />}
                  <div className=" text-warning text-xxs">
                    {grantErrors &&
                      grantErrors[grantUuid] &&
                      grantErrors[grantUuid]["note"]}
                  </div>
                </td>
              </tr>
            );
          })}
          {!disabled && <TotalApproved />}
          {!disabled && <BulkChange />}
        </tbody>
      </table>
    </>
  );
};

export default GrantTable;
