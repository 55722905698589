export const statusToMessage = (status: number) => {
  switch (status) {
    case 401:
      return "Incorrect email or password";
    case 404:
      return "Endpoint not reachable";
    case 700:
      return "Cannot connect to the server.";
    default:
      return "Unknow API error";
  }
};

export interface ApiError {
  status: number;
  message: string;
}

export const shapeApiError = (error: any) => {
  const parsedError = JSON.parse(JSON.stringify(error));

  if (error === "Network Error" || error === "Not Found") {
    return { status: 700, message: "Cannot connect to the server." };
  }

  return { status: parsedError.status, message: parsedError.message };
};
