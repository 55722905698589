import React from "react";
import { useUpdateNotes } from "./GrantHooks";
import GrantConsumer from "./GrantsContext";

interface Props {
  grantUuid: string;
  disabled?: boolean;
}

const NotesInput: React.FC<Props> = ({ grantUuid, disabled = false }) => {
  const { grants } = GrantConsumer();

  const updateNotes = useUpdateNotes();

  const note = grants[grantUuid].note;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateNotes(grantUuid, e.target.value);
  };

  return (
    <div className="w-full flex items-center pr-2">
      <textarea
        disabled={disabled}
        onChange={handleChange}
        className="w-full p-1 my-1 resize-none border rounded"
        value={note}
      />
    </div>
  );
};

export default NotesInput;
