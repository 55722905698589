import React from "react";

interface Props {
  onClick: () => void;
  text: string;
}

const AuthButton: React.FC<Props> = ({ onClick, text }) => (
  <button
    className="border rounded-lg border-maroon flex items-center py-2 px-3 uppercase text-maroon text-sm"
    onClick={onClick}
  >
    {text}
  </button>
);

export default AuthButton;
