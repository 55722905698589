import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import DropDownItem from "./DropDownItem";

interface Props {
  handleSelect?: (action: string) => void;
  currentAction?: string;
  disabled?: boolean;
}

type actionsStateToDisplayType = {
  [key: string]: string;
};

const DEFER = "DEFER";
const DECLINE = "DECLINE";
const APPROVE_WITH_MILESTONES = "APPROVE_WITH_MILESTONES";
const APPROVE = "APPROVE";
const WITHDRAW = "WITHDRAW";

export const actionStateToDisplay: actionsStateToDisplayType = {
  DEFER: "Defer",
  DECLINE: "Decline",
  APPROVE_WITH_MILESTONES: "Approve With Milestones",
  APPROVE: "Approve",
  WITHDRAW: "Withdraw",
};

const GrantDropDown = ({
  handleSelect,
  currentAction,
  disabled = false,
}: Props) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          disabled={disabled}
          className="items-center inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
        >
          {(currentAction && actionStateToDisplay[currentAction]) || "None"}
          {!disabled && (
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          )}
        </Menu.Button>
      </div>
      {handleSelect && (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <DropDownItem
                handleClick={() => handleSelect(APPROVE)}
                identifier={APPROVE}
                displayText={actionStateToDisplay[APPROVE]}
              />
            </div>
            <div className="py-1">
              <DropDownItem
                handleClick={() => handleSelect(APPROVE_WITH_MILESTONES)}
                identifier={APPROVE_WITH_MILESTONES}
                displayText={actionStateToDisplay[APPROVE_WITH_MILESTONES]}
              />
            </div>
            <div className="py-1">
              <DropDownItem
                handleClick={() => handleSelect(DECLINE)}
                identifier={DECLINE}
                displayText={actionStateToDisplay[DECLINE]}
              />
            </div>
            <div className="py-1">
              <DropDownItem
                handleClick={() => handleSelect(DEFER)}
                identifier={DEFER}
                displayText={actionStateToDisplay[DEFER]}
              />
            </div>
            <div className="py-1">
              <DropDownItem
                handleClick={() => handleSelect(WITHDRAW)}
                identifier={WITHDRAW}
                displayText={actionStateToDisplay[WITHDRAW]}
              />
            </div>
          </Menu.Items>
        </Transition>
      )}
    </Menu>
  );
};

export default GrantDropDown;
