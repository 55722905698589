import { updateGrants } from "./UpdateGrants";
import { updateGrantAction } from "./UpdateGrantAction";
import { updateApprovedAmount } from "./UpdateApprovedAmount";
import { updateSelected } from "./UpdateSelected";
import { updateNotes } from "./UpdateNotesField";
import { updateAuthorizationNote } from "./UpdateAuthorizationNote";
import { updateResolutions } from "./UpdateResolutions";
import { toggleSelectAll } from "./ToggleSelectAll";

export enum GrantActionTypes {
  updateGrants = "updateGrants",
  updateGrantAction = "updateGrantAction",
  updateApprovedAmount = "updateApprovedAmount",
  updateSelected = "updateSelected",
  updateNotes = "updateNotes",
  updateAuthorizationNote = "updateAuthorizationNote",
  updateResolutions = "updateResolutions",
  toggleSelectAll = "toggleSelectAll",
}

export type GrantAction =
  | updateGrants
  | updateGrantAction
  | updateApprovedAmount
  | updateResolutions
  | updateSelected
  | updateNotes
  | updateAuthorizationNote
  | toggleSelectAll;
