import { createAction } from "@reduxjs/toolkit";
import { GrantState } from "../../../types";
import { GrantActionTypes } from "../types";

export type updateSelected = {
  type: GrantActionTypes.updateSelected;
};

const action = createAction<
  {
    grantUuid: string;
  },
  GrantActionTypes.updateSelected
>(GrantActionTypes.updateSelected);

export const reducer = (
  state: GrantState,
  {
    payload,
  }: {
    payload: {
      grantUuid: string;
    };
  }
) => {
  const { grantUuid } = payload;
  state.grants[grantUuid].selected = !state.grants[grantUuid].selected;
};

export default action;
