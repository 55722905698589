import * as React from "react";
import { Nullable } from "../../types";
import { Grant, GrantState } from "../types";
import { grantReducer, initialGrantState } from "./grantsReducer";

export interface GrantContextInterface {
  grants: Nullable<Grant[]>;
}

const GrantContext = React.createContext(initialGrantState);

type GrantProps = {
  children: React.ReactNode;
};

export function GrantProvider({ children }: GrantProps) {
  const [state, dispatch] = React.useReducer(grantReducer, initialGrantState);

  const value: GrantState = {
    ...state,
    dispatch,
  };

  return (
    <GrantContext.Provider value={value}>{children}</GrantContext.Provider>
  );
}

export default function GrantConsumer() {
  return React.useContext(GrantContext);
}
