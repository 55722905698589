import React from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { useLocation, Navigate } from "react-router-dom";
import AuthConsumer from "./authHooks";
import Cookies from "js-cookie";

interface Props {
  children: JSX.Element;
}

const RequireAuth: React.FC<Props> = ({ children }) => {
  const { setAuthedGlobal } = AuthConsumer() || {};
  const [checked, setChecked] = React.useState(false);
  const [jwtAuthed, setJwtAuthed] = React.useState(false);

  React.useEffect(() => {
    const jwt = Cookies.get("token");
    setChecked(true);

    if (jwt) {
      const { exp } = jwt_decode(jwt) as { exp: number };
      const expired = exp <= Date.now() / 1000;

      if (!expired) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
        setJwtAuthed(true);

        setAuthedGlobal && setAuthedGlobal(true);
      }
    }
  }, [setChecked, setJwtAuthed, checked, setAuthedGlobal]);

  const location = useLocation();

  if (!checked) {
    return <div>Loading</div>;
  }

  return jwtAuthed === true ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
};

export default RequireAuth;
