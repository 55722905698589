import Button from "../general/Button";
import { useSaveResolution } from "./GrantHooks";
import { GrantErrors } from "./types";

interface Props {
  setErrors: (grantErrors: GrantErrors) => void;
  customText?: string;
  authorisationUuid?: string;
}

const ResolutionSubmitButton: React.FC<Props> = () => {
  const saveNewResolition = useSaveResolution();

  const handleClick = () => {
    saveNewResolition();
  };

  return <Button onClick={handleClick} text={"Submit"} />;
};

export default ResolutionSubmitButton;
