import { currencyFormatter } from "../general/utils";
import GrantConsumer from "./GrantsContext";
import { getGrantsTotal } from "./grantsSelectors";

const TotalApproved = () => {
  const state = GrantConsumer();
  const total = getGrantsTotal(state);
  return (
    <tr>
      <td className="w-10" />
      <td className="w-44" />
      <td />
      <td />
      <td className="w-40 pr-4" />
      <td className="pl-2 w-40 pr-4 text-sm text-gun font-bold">
        <div className="h-12 flex items-center">
          {currencyFormatter.format(total)}
        </div>
      </td>
      <td className="w-52 pr-4" />
      <td className="w-80" />
    </tr>
  );
};

export default TotalApproved;
