import React from "react";
import { useUpdateSelected } from "./GrantHooks";
import GrantConsumer from "./GrantsContext";
import Checkbox from "./Checkbox";

interface Props {
  grantUuid: string;
}

const GrantCheckbox: React.FC<Props> = ({ grantUuid }) => {
  const { grants } = GrantConsumer();

  const selected = grants[grantUuid].selected;
  const updateSelected = useUpdateSelected();
  const handleClick = () => {
    updateSelected(grantUuid);
  };

  return <Checkbox handleClick={handleClick} selected={selected} />;
};

export default GrantCheckbox;
