import { createAction } from "@reduxjs/toolkit";
import { GrantState } from "../../../types";
import { GrantActionTypes } from "../types";

export type toggleSelectAll = {
  type: GrantActionTypes.toggleSelectAll;
};

const action = createAction<
  {
    selectAll: boolean;
  },
  GrantActionTypes.toggleSelectAll
>(GrantActionTypes.toggleSelectAll);

export const reducer = (
  state: GrantState,
  {
    payload,
  }: {
    payload: {
      selectAll: boolean;
    };
  }
) => {
  Object.keys(state.grants).forEach((grantUuid) => {
    state.grants[grantUuid].selected = payload.selectAll;
  });
};

export default action;
