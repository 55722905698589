import React from "react";
import Api from "../api/api";
import { Nullable } from "../types";
import { User } from "./types";

export const useUserInformation = () => {
  const [user, setUser] = React.useState<Nullable<User>>(null);

  const getUser = React.useCallback(() => {
    const fetchUser = async () => {
      const user = await Api.getMe();
      setUser(user);
    };
    fetchUser();
  }, []);

  return { user, getUser };
};
