import React from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import DropDownItem from "../../grants/GrantDropDown/DropDownItem";
import { Team } from "../types";
import { Nullable } from "../../types";

interface Props {
  teams: Nullable<Team[]>;
  setCurrentTeam: (teamUuuid: string) => void;
  currentTeam?: Team;
  loading: boolean;
}

export default function TeamDropDown({
  teams,
  setCurrentTeam,
  currentTeam,
  loading,
}: Props) {
  const handleClick = async (uuid: string) => {
    await setCurrentTeam(uuid);
  };

  const getText = () => {
    if (loading) {
      return "Loading...";
    }
    return currentTeam?.name ? currentTeam?.name : "Select a team";
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          disabled={loading}
          className="items-center inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
        >
          {getText()}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          {teams?.map((team: Team) => (
            <div className="py-1" key={team.uuid}>
              <DropDownItem
                key={team.uuid}
                handleClick={handleClick}
                identifier={team.uuid}
                displayText={team.name}
              />
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
