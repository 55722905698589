import React from "react";
import CurrencyInput from "react-currency-input-field";
import { useUpdateApprovedAmount } from "./GrantHooks";
import GrantConsumer from "./GrantsContext";

import { CurrencyInputProps } from "react-currency-input-field";

interface Props {
  grantUuid: string;
  disabled?: boolean;
}

const AmountInput: React.FC<Props> = ({ grantUuid, disabled = false }) => {
  const prefix = "$";

  const updateApprovedAmount = useUpdateApprovedAmount();

  const { grants } = GrantConsumer();

  const approvedAmount = grants[grantUuid].approvedAmount;

  const [value, setValue] = React.useState<string | number>(
    (approvedAmount / 100).toFixed(2)
  );

  /**
   * Handle validation
   */
  const handleOnValueChange: CurrencyInputProps["onValueChange"] = (
    value,
    _,
    values
  ): void => {
    if (!value) {
      setValue("");
      updateApprovedAmount(grantUuid, 0);
      return;
    }

    if (Number.isNaN(Number(value))) {
      return;
    }

    // if (Number(value) > limit) {
    //   setErrorMessage(`Max: ${prefix}${limit}`);
    //   setClassName("is-invalid");
    //   setValue(value);
    //   return;
    // }

    setValue(value);

    if (values?.float || values?.float === 0) {
      const valueInCents = values.float * 100;
      updateApprovedAmount(grantUuid, Math.round(valueInCents * 100) / 100);
    }
  };

  return (
    <div className="flex">
      <CurrencyInput
        className={
          "text-center bg-white text-sm border h-10 rounded w-32 border-ghost"
        }
        style={{ textAlign: "left", paddingLeft: 4 }}
        value={value || approvedAmount / 100}
        onValueChange={handleOnValueChange}
        placeholder="Please enter a number"
        prefix={prefix}
        step={1}
      />
    </div>
  );
};

export default AmountInput;
