import React from "react";
import {
  useFetchGrants,
  useSortedGrants,
  useUpdateAuthorizationNote,
  useUpdateGrantAction,
} from "./GrantHooks";
import GrantConsumer from "./GrantsContext";
import GrantsSubmitButton from "./GrantsSubmitButton";
import ReactLoading from "react-loading";
import DatePicker from "react-datepicker";
import GrantTable from "./GrantsTable";
import { actionState } from "./GrantsContext/actions/UpdateGrantAction";
import { GrantErrors, Resolution } from "./types";
import { Nullable } from "../types";
import CheckIcon from "./CheckIcon";
import ResolutionSubmitButton from "./ResolutionSubmitButton";
import { Team } from "../teams/types";

interface Props {
  currentTeam?: Team;
  loadingTeams: boolean;
}

const Grants = ({ currentTeam, loadingTeams }: Props) => {
  const { fetching } = useFetchGrants();
  const { authorisationNotes } = GrantConsumer();
  const [grantErrors, setGrantErrors] = React.useState<GrantErrors>({});
  const [startDate, setStartDate] = React.useState(new Date());
  const [selectedResolutionUuid, setSelectedResolutionUuid] =
    React.useState<Nullable<string>>(null);

  const sortedGrants = useSortedGrants();

  const updateAuthorizationNote = useUpdateAuthorizationNote();

  const updateGrantAction = useUpdateGrantAction();

  const handleSelect = (grantUuid: string) => (action: string) =>
    updateGrantAction(grantUuid, action as actionState);

  const handleChangeAuthorisationNote = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    updateAuthorizationNote({
      uuid: "new-note",
      note: e.target.value,
      resolutionAt: startDate,
    });
  };

  if (fetching || loadingTeams) {
    return (
      <div className="flex flex-1 items-center justify-center text-cloud">
        <ReactLoading type={"spin"} color={"#E8E2D5"} height={30} width={30} />
      </div>
    );
  }

  const resolutionCreationTeam = currentTeam?.roles.includes("resolution");

  const resolutionConsumerTeam = currentTeam?.roles.includes("use_resolution");

  if (!sortedGrants.length && !resolutionCreationTeam) {
    return (
      <div className="flex flex-1 items-center justify-center text-cloud">
        No grants available to review
      </div>
    );
  }

  const renderResolution = (
    index: number,
    authorisationNote: Resolution,
    disabled = false
  ) => (
    <div className="py-5" key={index}>
      <div className="flex">
        {!resolutionCreationTeam && (
          <button
            onClick={() =>
              selectedResolutionUuid === authorisationNote.uuid
                ? setSelectedResolutionUuid(null)
                : setSelectedResolutionUuid(authorisationNote.uuid)
            }
            className="mr-2 border border-ghost border-1 rounded w-5 h-5 flex items-center justify-center"
          >
            <div>
              {authorisationNote.uuid === selectedResolutionUuid && (
                <CheckIcon />
              )}
            </div>
          </button>
        )}
        <div className="text-cloud pr-4">Resolution</div>
        <DatePicker
          disabled={!!authorisationNote.resolutionAt}
          dateFormat={"dd/MM/yyyy"}
          selected={
            authorisationNote.resolutionAt
              ? new Date(authorisationNote.resolutionAt)
              : startDate
          }
          onChange={(date: Date) => setStartDate(date)}
        />
      </div>
      <div className="w-full flex items-center mb-4">
        <textarea
          disabled={disabled}
          onChange={handleChangeAuthorisationNote}
          className="w-full p-1 my-1 resize-none border rounded h-40"
          value={authorisationNote.note || ""}
        />
      </div>
    </div>
  );

  const existingResolutions = () => {
    return (
      <div className="mt-20">
        {authorisationNotes &&
          Object.keys(authorisationNotes)
            .filter(
              (authorisationNoteUuid) => authorisationNoteUuid !== "new-note"
            )
            .map((authorisationNoteUuid, index) => {
              return renderResolution(
                index,
                authorisationNotes[authorisationNoteUuid],
                true
              );
            })}
      </div>
    );
  };

  const newResolution =
    authorisationNotes && authorisationNotes["new-note"]
      ? authorisationNotes["new-note"]
      : { uuid: "new-note", note: "" };

  return (
    <div className="p-10">
      <h1 className="ml-2 text-xl font-bold font-playfairDisplay">
        {resolutionCreationTeam
          ? `Please provide the ${currentTeam?.name} meeting resolution`
          : "Please review these grants"}
      </h1>
      <div className=" ml-2 text-cloud text-xs mt-3">
        {resolutionCreationTeam
          ? "This is the resolution from the most recent meeting."
          : "You can either Approve, vary and Approve, Decline or Defer. A summary will be provided for the minutes once submitted."}
      </div>
      {!resolutionCreationTeam && (
        <div className="mt-10">
          <GrantTable
            grants={sortedGrants}
            handleSelect={handleSelect}
            grantErrors={grantErrors}
          />
        </div>
      )}
      <>
        {resolutionCreationTeam && renderResolution(0, newResolution)}
        {resolutionCreationTeam && (
          <ResolutionSubmitButton
            setErrors={(grantErrors: GrantErrors) =>
              setGrantErrors(grantErrors)
            }
            customText={resolutionCreationTeam ? "Submit" : undefined}
          />
        )}
        {(resolutionCreationTeam || resolutionConsumerTeam) &&
          existingResolutions()}
      </>
      {!resolutionCreationTeam && (
        <GrantsSubmitButton
          setErrors={(grantErrors: GrantErrors) => setGrantErrors(grantErrors)}
          customText={resolutionCreationTeam ? "Submit" : undefined}
          authorisationUuid={selectedResolutionUuid || undefined}
        />
      )}
    </div>
  );
};

export default Grants;
