import { createAction } from "@reduxjs/toolkit";
import { GrantState, Resolution } from "../../../types";
import { GrantActionTypes } from "../types";

export type updateResolutions = {
  type: GrantActionTypes.updateResolutions;
};

const action = createAction<Resolution[], GrantActionTypes.updateResolutions>(
  GrantActionTypes.updateResolutions
);

export const reducer = (
  state: GrantState,
  { payload }: { payload: Resolution[] }
) => {
  state.authorisationNotes = payload.reduce((accum, resolution) => {
    return {
      ...accum,
      [resolution.uuid]: resolution,
    };
  }, {});
};

export default action;
