import { useLocation } from "react-router-dom";
import Logo from "../assets/Logo";
import { Formik } from "formik";
import * as Yup from "yup";
import AuthConsumer from "./authHooks";
import { statusToMessage } from "../general/errors";

const Login = () => {
  const { login, authErrors } = AuthConsumer() || {};
  const { state } = useLocation();

  const handleLogin = (values: any) => {
    const { email, password } = values;
    if (login) {
      login({ email, password, path: state?.path });
    }
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter your email"),
    password: Yup.string().required("Please enter your password"),
  });

  return (
    <div className="h-full flex flex-col flex-1 justify-center items-center">
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={loginSchema}
        onSubmit={(values: any, { setSubmitting }) => {
          handleLogin(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form
            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            onSubmit={handleSubmit}
          >
            <div className="m-8">
              <Logo />
            </div>
            <div className="mb-5 relative">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Username
              </label>
              <input
                name={"email"}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="text"
                placeholder="Username"
              />
              <div className="text-red-500 text-xs italic absolute pt-1">
                {errors["email"]}
              </div>
            </div>

            <div className="mb-8 relative">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Password
              </label>
              <input
                name={"password"}
                onChange={handleChange}
                className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                placeholder=""
              />

              <div className=" text-red-500 text-xs italic absolute pt-1">
                {errors["password"] ||
                  (authErrors &&
                    authErrors?.status &&
                    statusToMessage(authErrors.status))}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Sign In
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
