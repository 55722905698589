import React from "react";
import { Menu } from "@headlessui/react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  identifier: string;
  displayText: string;
  handleClick: (action: string) => void;
}

const DropDownItem: React.FC<Props> = ({
  identifier,
  handleClick,
  displayText,
}) => (
  <Menu.Item>
    {({ active }) => (
      <div
        onClick={() => handleClick(identifier)}
        className={classNames(
          active ? "bg-gray-100 text-gray-900" : "text-gray-700",
          "block px-4 py-2 text-sm first-letter:uppercase"
        )}
      >
        {displayText}
      </div>
    )}
  </Menu.Item>
);

export default DropDownItem;
