const CheckIcon = () => {
  return (
    <svg
      width="9"
      height="7"
      viewBox="0 0 9 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.24264 1.41421L6.82843 0L2.83631 3.99211L1.41421 2.57001L0 3.98422L2.83421 6.81843L2.83429 6.81836L2.83639 6.82046L8.24264 1.41421Z"
        fill="#0070D2"
      />
    </svg>
  );
};

export default CheckIcon;
