import { createAction } from "@reduxjs/toolkit";
import { GrantState } from "../../../types";
import { GrantActionTypes } from "../types";

export type updateApprovedAmount = {
  type: GrantActionTypes.updateApprovedAmount;
};

const action = createAction<
  {
    grantUuid: string;
    amount: number;
  },
  GrantActionTypes.updateApprovedAmount
>(GrantActionTypes.updateApprovedAmount);

export const reducer = (
  state: GrantState,
  {
    payload,
  }: {
    payload: {
      grantUuid: string;
      amount: number;
    };
  }
) => {
  const { grantUuid, amount } = payload;
  state.grants[grantUuid].approvedAmount = amount;
};

export default action;
