import { createAction } from "@reduxjs/toolkit";
import { GrantState } from "../../../types";
import { GrantActionTypes } from "../types";

export type updateGrantAction = {
  type: GrantActionTypes.updateGrantAction;
};

export type actionState =
  | "APPROVE"
  | "DECLINE"
  | "DEFER"
  | "APPROVE_WITH_MILESTONES"
  | "WITHDRAW";

const action = createAction<
  {
    grantUuid: string;
    actionState: actionState;
  },
  GrantActionTypes.updateGrantAction
>(GrantActionTypes.updateGrantAction);

export const reducer = (
  state: GrantState,
  {
    payload,
  }: {
    payload: {
      grantUuid: string;
      actionState: actionState;
    };
  }
) => {
  const { grantUuid, actionState } = payload;
  state.grants[grantUuid].action = actionState;
};

export default action;
