import React from "react";
import { Maybe } from "../types";
import GrantDropDown from "./GrantDropDown";
import { useUpdateGrantAction } from "./GrantHooks";
import GrantConsumer from "./GrantsContext";
import { actionState } from "./GrantsContext/actions/UpdateGrantAction";

const BulkChange = () => {
  const { grants } = GrantConsumer();
  const [bulkAction, setBulkAction] = React.useState<Maybe<string>>();

  const updateGrantAction = useUpdateGrantAction();

  const handleSetBulkActions = (action: string) => {
    Object.keys(grants).map((grantUuid) =>
      updateGrantAction(grantUuid, action as actionState)
    );
    setBulkAction(action);
  };

  return (
    <tr>
      <td className="w-10" />
      <td className="w-44" />
      <td />
      <td />
      <td className="w-40 pr-4" />
      <td className="w-52 pr-4" />
      <td className="w-40">
        <div className="h-12 flex items-center relative">
          <div className="absolute -left-20">Bulk change:</div>
          <GrantDropDown
            currentAction={bulkAction}
            handleSelect={handleSetBulkActions}
          />
        </div>
      </td>
      <td className="w-80" />
    </tr>
  );
};

export default BulkChange;
