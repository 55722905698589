import axios from "axios";
// import Cookies from "js-cookie";
import { Grant, Resolution } from "../grants/types";
import { Nullable } from "../types";
import { User } from "../user/types";
import {
  AuthApiResponse,
  AuthApiRequest,
  GrantsApiResponse,
  TeamsApiResponse,
} from "./types";

const baseUrl = process.env["REACT_APP_API_URL"];

// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response && error.response.data) {
//       if (error?.response?.data?.statusCode === 401) {
//         Cookies.remove("token");
//         window.location.replace("/login");
//       }
//       return Promise.reject(error.response.data);
//     }
//     return Promise.reject(error.message);
//   }
// );

const Api = {
  login: async ({
    email,
    password,
  }: AuthApiRequest): Promise<AuthApiResponse> => {
    const response = await axios.post(`${baseUrl}/auth/login`, {
      email,
      password,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.accessToken}`;
    return response.data;
  },
  getGrants: async (): Promise<GrantsApiResponse> => {
    const response = await axios.get(`${baseUrl}/grants`, {});
    return response.data;
  },
  getResolutions: async (): Promise<Resolution[]> => {
    const response = await axios.get(`${baseUrl}/authorisation-notes`, {});
    return response.data;
  },
  getTeams: async (): Promise<TeamsApiResponse> => {
    const response = await axios.get(`${baseUrl}/users/me/teams`, {});
    return response.data;
  },

  setActiveTeam: async (uuid: string): Promise<User> => {
    const response = await axios.put(`${baseUrl}/users/me`, {
      currentTeamUuid: uuid,
    });
    return response.data;
  },

  getMe: async (): Promise<User> => {
    const response = await axios.get(`${baseUrl}/users/me`, {});
    return response.data;
  },

  updateGrants: async (
    grants: Grant[],
    authorisationUuid: Nullable<string>
  ): Promise<GrantsApiResponse> => {
    const response = await axios.put(`${baseUrl}/grants/update`, {
      grants,
      authorisationUuid,
    });
    return response.data;
  },

  newResolution: async ({
    note,
    resolutionAt,
  }: {
    note: string;
    resolutionAt: Date;
  }): Promise<void> => {
    const response = await axios.post(`${baseUrl}/authorisation-notes`, {
      note,
      resolutionAt,
    });
    return response.data;
  },
};

export default Api;
