import { createReducer, Reducer } from "@reduxjs/toolkit";

import { GrantState } from "../types";
import updateGrants, {
  reducer as updateGrantReducer,
} from "./actions/UpdateGrants";
import updateGrantAction, {
  reducer as updateGrantActionReducer,
} from "./actions/UpdateGrantAction";
import updateApprovedAmount, {
  reducer as updateApprovedAmountReducer,
} from "./actions/UpdateApprovedAmount";
import updateAuthorisationNote, {
  reducer as updateAuthorisationNoteReducer,
} from "./actions/UpdateAuthorizationNote";
import updateSelected, {
  reducer as updateSelectedReducer,
} from "./actions/UpdateSelected";
import updateNotes, {
  reducer as updateNotesReducer,
} from "./actions/UpdateNotesField";
import updateResolutions, {
  reducer as updateResolutionsReducer,
} from "./actions/UpdateResolutions";
import { GrantAction } from "./actions/types";
import toggleSelectAll, {
  reducer as toggleSelectAllReducer,
} from "./actions/ToggleSelectAll";

export const initialGrantState: GrantState = {
  grants: {},
  authorisationNotes: null,
  dispatch: (action: GrantAction) => undefined,
};

export const grantReducer: Reducer<GrantState, GrantAction> = createReducer(
  initialGrantState,
  (builder) =>
    builder
      .addCase(updateGrants, updateGrantReducer)
      .addCase(updateGrantAction, updateGrantActionReducer)
      .addCase(updateApprovedAmount, updateApprovedAmountReducer)
      .addCase(updateSelected, updateSelectedReducer)
      .addCase(updateNotes, updateNotesReducer)
      .addCase(updateAuthorisationNote, updateAuthorisationNoteReducer)
      .addCase(updateResolutions, updateResolutionsReducer)
      .addCase(toggleSelectAll, toggleSelectAllReducer)
);
