import React from "react";
import Api from "../api/api";
import { toast } from "react-hot-toast";
import GrantConsumer from "./GrantsContext";
import updateGrants from "./GrantsContext/actions/UpdateGrants";
import updateResolutions from "./GrantsContext/actions/UpdateResolutions";
import updateSelected from "./GrantsContext/actions/UpdateSelected";
import toggleSelectAll from "./GrantsContext/actions/ToggleSelectAll";
import updateNotes from "./GrantsContext/actions/UpdateNotesField";
import updateGrantAction, {
  actionState,
} from "./GrantsContext/actions/UpdateGrantAction";
import updateAuthorizationNote from "./GrantsContext/actions/UpdateAuthorizationNote";
import updateApprovedAmount from "./GrantsContext/actions/UpdateApprovedAmount";
import { Grant, Resolution } from "./types";
import {
  getAllSelected,
  getNewResoltuion,
  getSelectedGrants,
  getSortedGrants,
} from "./grantsSelectors";
import { useNavigate } from "react-router";
import { Nullable } from "../types";

export const useFetchGrants = () => {
  const { dispatch } = GrantConsumer();
  const [fetching, setFetching] = React.useState(false);

  React.useEffect(() => {
    const fetchGrants = async () => {
      try {
        setFetching(true);
        const grants: Grant[] = await Api.getGrants();
        dispatch(updateGrants(grants));
        const resolutions: Resolution[] = await Api.getResolutions();
        dispatch(updateResolutions(resolutions));
      } finally {
        setFetching(false);
      }
    };
    fetchGrants();
  }, [dispatch]);

  return {
    fetching,
  };
};

export const useUpdateGrantAction = () => {
  const { dispatch } = GrantConsumer();

  return React.useCallback(
    (grantUuid: string, actionState: actionState) => {
      dispatch(updateGrantAction({ grantUuid, actionState }));
    },
    [dispatch]
  );
};

export const useUpdateAuthorizationNote = () => {
  const { dispatch } = GrantConsumer();

  return React.useCallback(
    (authorisationNote: Resolution) => {
      dispatch(updateAuthorizationNote({ authorisationNote }));
    },
    [dispatch]
  );
};

export const useUpdateApprovedAmount = () => {
  const { dispatch } = GrantConsumer();

  return React.useCallback(
    (grantUuid: string, amount: number) => {
      dispatch(updateApprovedAmount({ grantUuid, amount }));
    },
    [dispatch]
  );
};

export const useUpdateSelected = () => {
  const { dispatch } = GrantConsumer();

  return React.useCallback(
    (grantUuid: string) => {
      dispatch(updateSelected({ grantUuid }));
    },
    [dispatch]
  );
};

export const useAllSelected = () => {
  const state = GrantConsumer();

  return getAllSelected(state);
};

export const useToggleAllSelected = () => {
  const { dispatch } = GrantConsumer();

  const allSelected = useAllSelected();

  return React.useCallback(() => {
    dispatch(toggleSelectAll({ selectAll: !allSelected }));
  }, [dispatch, allSelected]);
};

export const useSortedGrants = () => {
  const state = GrantConsumer();

  return getSortedGrants(state);
};

export const useUpdateNotes = () => {
  const { dispatch } = GrantConsumer();

  return React.useCallback(
    (grantUuid: string, note: string) => {
      dispatch(updateNotes({ grantUuid, note }));
    },
    [dispatch]
  );
};

export const useSaveSelectedGrants = (authorisationUuid?: string) => {
  const state = GrantConsumer();

  const navigate = useNavigate();

  const selectedGrants = getSelectedGrants(state);

  return React.useCallback(() => {
    const updateSelectedGrants = async (
      grants: Grant[],
      authorisationUuid: Nullable<string>
    ) => {
      try {
        await Api.updateGrants(
          grants.filter((grant) => grant.action),
          authorisationUuid || null
        );
        navigate("/summary");
      } catch (e: any) {
        toast.error("Failed to submit grants");
        console.log(e);
      }
    };
    updateSelectedGrants(selectedGrants, authorisationUuid || null);
  }, [selectedGrants, navigate, authorisationUuid]);
};

export const useSaveResolution = () => {
  const state = GrantConsumer();
  const { dispatch } = GrantConsumer();

  const newResolution = getNewResoltuion(state);

  return React.useCallback(() => {
    const saveNewResolution = async () => {
      newResolution?.resolutionAt &&
        (await Api.newResolution({
          resolutionAt: newResolution.resolutionAt,
          note: newResolution.note,
        }));
      dispatch(updateResolutions([]));
      const resolutions: Resolution[] = await Api.getResolutions();
      dispatch(updateResolutions(resolutions));
    };
    saveNewResolution();
  }, [newResolution, dispatch]);
};
