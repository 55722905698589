import React from "react";
import { Toaster } from "react-hot-toast";
import "./App.css";
import Header from "./header/Header";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "./auth/RequireAuth";
import Login from "./auth/login";
import Grants from "./grants";
import GrantsSummary from "./grants/GrantsSummary";
import "react-datepicker/dist/react-datepicker.css";
import { useTeams } from "./teams/teamhooks";

function App() {
  const { currentTeam, teams, setCurrentTeam, loading } = useTeams();
  return (
    <div className="bg-white h-full w-full flex flex-1 flex-col">
      <Toaster />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <>
                <Header
                  loading={loading}
                  teams={teams}
                  setCurrentTeam={setCurrentTeam}
                  currentTeam={currentTeam}
                />
                <Grants currentTeam={currentTeam} loadingTeams={loading} />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/summary"
          element={
            <RequireAuth>
              <>
                <Header
                  loading={loading}
                  teams={teams}
                  setCurrentTeam={setCurrentTeam}
                  currentTeam={currentTeam}
                />
                <GrantsSummary />
              </>
            </RequireAuth>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
