import { createAction } from "@reduxjs/toolkit";
import { Grant, GrantState } from "../../../types";
import { GrantActionTypes } from "../types";

export type updateGrants = {
  type: GrantActionTypes.updateGrants;
};

const action = createAction<Grant[], GrantActionTypes.updateGrants>(
  GrantActionTypes.updateGrants
);

export const reducer = (
  state: GrantState,
  { payload }: { payload: Grant[] }
) => {
  state.grants = payload.reduce((accum, grant) => {
    return {
      ...accum,
      [grant.uuid]: {
        ...grant,
        selected: true,
        approvedAmount: grant.approvedAmount
          ? grant.approvedAmount
          : grant.requestedAmount,
      },
    };
  }, {});
};

export default action;
