import React from "react";
import Api from "../api/api";
import AuthConsumer from "../auth/authHooks";
import GrantConsumer from "../grants/GrantsContext";
import updateGrants from "../grants/GrantsContext/actions/UpdateGrants";
import { Grant } from "../grants/types";
import { Nullable } from "../types";
import { useUserInformation } from "../user/userHooks";
import { Team } from "./types";

export const useTeams = () => {
  const { dispatch } = GrantConsumer();
  const [teams, setTeams] = React.useState<Nullable<Team[]>>(null);
  const { user, getUser } = useUserInformation();
  const { authed } = AuthConsumer() || {};
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const getTeams = async () => {
      const teams = await Api.getTeams();
      setTeams(teams);
      getUser();
    };
    if (authed) {
      getTeams();
    }
  }, [getUser, authed]);

  const setCurrentTeam = React.useCallback(
    (teamUuid: string) => {
      const setActiveTeam = async (teamUuid: string) => {
        setLoading(true);
        await Api.setActiveTeam(teamUuid);
        await getUser();
        const grants: Grant[] = await Api.getGrants();
        dispatch(updateGrants(grants));
        setLoading(false);
      };
      setActiveTeam(teamUuid);
    },
    [dispatch, getUser]
  );

  return {
    teams,
    user,
    setCurrentTeam,
    currentTeam: teams?.find((team) => team.uuid === user?.currentTeamUuid),
    loading,
  };
};
